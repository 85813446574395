import constants from "@/constants";
import globalModel from "@/models/global.model";
import verifyModel from "@/models/verify.model";
import { REGEX_CAPTCHA, REGEX_MOBLE } from "@/utils/regex";
import { useReactive } from "ahooks";
import dayjs from "dayjs";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Checkbox, Field, Form, Toast } from "react-vant";
import styles from "./index.module.css";

export default () => {
  const state = useReactive<any>({
    tab: "captcha",
    mobile: "",
    captcha: "",
    countdown: 0,
    countdownIndex: null,
    isAgreed: false,
  });
  const nav = useNavigate();
  const [form] = Form.useForm();

  useEffect(() => {
    const mobile = localStorage.getItem("mobile");
    const code = localStorage.getItem("code");
    form.setFieldValue("mobile", mobile ? mobile.replace(/"/g, "") : "");
    form.setFieldValue("code", code ? code.replace(/"/g, "") : "");
  }, [form]);

  useEffect(() => {
    const sendSMSCodeTime = localStorage.getItem("code-time");
    if (sendSMSCodeTime) {
      const diff = dayjs().diff(dayjs(sendSMSCodeTime), "s");
      if (diff < 60) {
        state.countdown = 60 - diff;
      }
    }
  }, []);

  useEffect(() => {
    if (state.countdown > 0 && !state.countdownIndex) {
      state.countdownIndex = setInterval(() => {
        state.countdown = state.countdown - 1;
      }, 1000);
    }
    if (state.countdown <= 0) {
      if (state.countdownIndex) {
        state.countdownIndex = clearInterval(state.countdownIndex);
      }
      localStorage.setItem("code-time", "");
    }
  }, [state.countdown, state.countdownIndex]);

  const handleSendSMS = async () => {
    try {
      const values = await form.validateFields(["mobile"]);
      state.mobile = values.mobile;

      // @ts-ignore
      const captcha = new TencentCaptcha(
        constants.tencent.captchaId,
        async (data: any) => {
          if (data?.ret !== 0) {
            Toast(data?.errorMessage ?? "验证失败");
            return;
          }
          const update = Toast({
            type: "loading",
            message: "正在发送...",
            forbidClick: true,
            duration: 0,
          });
          const res = await verifyModel.sendVerifyCode({
            mobile: state.mobile,
            randstr: data.randstr,
            ticket: data.ticket,
          });
          if (res?.code === 200) {
            update.config({
              type: "success",
              message: "发送成功",
              forbidClick: true,
            });
            localStorage.setItem(
              "code-time",
              dayjs().format("YYYY-MM-DD HH:mm:ss")
            );
            state.countdown = 60;
          } else {
            update.config({
              type: "fail",
              message: "发送失败",
              forbidClick: true,
            });
          }
          captcha.destroy();
          setTimeout(() => {
            update.clear();
          }, 2000);
        }
      );
      captcha.show();
    } catch (e: any) {
      console.log(e);
      Toast(e.errorFields[0].errors[0]);
    }
  };

  const onFinish = async (values: any) => {
    const update = Toast({
      type: "loading",
      message: "正在登录...",
      forbidClick: true,
      duration: 0,
    });
    const code = localStorage.getItem("code");
    const res = await globalModel.login({
      type: state.tab,
      ...values,
      code,
    });
    if (res?.code === 200) {
      update.config({
        type: "success",
        message: "登录成功",
        forbidClick: true,
      });
      localStorage.setItem("mobile", state.mobile);
      state.mobile = "";
      state.captcha = "";
      nav("/mine", { replace: true });
    } else {
      update.config({
        type: "fail",
        message: "登录失败",
        forbidClick: true,
      });
    }
    setTimeout(() => {
      update.clear();
    }, 2000);
  };

  return (
    <div className={styles.page}>
      <div className={styles.header}>
        <div className={styles.hero} />
        <div className={styles.info}>
          <div className={styles.logo} />
          <div className={styles.slogan} />
        </div>
      </div>
      <div className={styles.container}>
        <div className={styles.title}>Hello!</div>
        <div className={styles.subtitle}>欢迎来到拓梦空间</div>
        <Form
          className={styles.form}
          layout="horizontal"
          form={form}
          onFinish={onFinish}
          footer={
            <div className={styles.footer}>
              <Button
                className={styles.submit}
                type="primary"
                size="large"
                round
                block
                disabled={!state.isAgreed}
                onClick={async () => {
                  try {
                    await form.validateFields();
                    form.submit();
                  } catch (e: any) {
                    if (e && e.errorFields) {
                      Toast({
                        message: e.errorFields[0]?.errors[0],
                        position: "top",
                      });
                    }
                  }
                }}
              >
                登录
              </Button>
              <div className={styles.docs}>
                <Checkbox
                  checked={state.isAgreed}
                  onChange={(e) => (state.isAgreed = e)}
                >
                  已阅读并同意
                  <a
                    onClick={(e) => {
                      e.stopPropagation();
                      nav("/agreement");
                    }}
                  >
                    《用户协议》
                  </a>
                  和
                  <a
                    onClick={(e) => {
                      e.stopPropagation();
                      nav("/policy");
                    }}
                  >
                    《隐私政策》
                  </a>
                </Checkbox>
              </div>
            </div>
          }
        >
          <div className={styles.item}>
            <div className={styles.label}>手机号</div>
            <Form.Item
              noStyle
              name="mobile"
              rules={[
                { required: true, message: "请输入手机号" },
                {
                  pattern: REGEX_MOBLE,
                  message: "请输入正确的手机号",
                },
              ]}
            >
              <Field placeholder="请输入手机号" type="tel" clearable />
            </Form.Item>
          </div>
          <div className={styles.item}>
            <div className={styles.label}>验证码</div>
            <Form.Item
              name="captcha"
              rules={[
                { required: true, message: "请输入验证码" },
                {
                  pattern: REGEX_CAPTCHA,
                  message: "请输入正确的验证码",
                },
              ]}
              noStyle
            >
              <Field
                placeholder="请输入验证码"
                type="number"
                maxLength={6}
                suffix={
                  <a
                    className={styles.countdown}
                    onClick={async () => {
                      if (!state.isAgreed) {
                        Toast({
                          message: "请阅读并同意协议",
                        });
                        return;
                      }
                      if (state.countdown <= 0) {
                        await handleSendSMS();
                      }
                    }}
                  >
                    {state.countdown <= 0
                      ? "获取验证码"
                      : `${state.countdown}s`}
                  </a>
                }
                clearable
              />
            </Form.Item>
          </div>
        </Form>
      </div>
    </div>
  );
};
